import React, { Component } from "react";
import {
  Card,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Status,
  Pagination,
  ButtonIcon,
  TabsCustom,
  TabsCustomHeader,
  TabsCustomMenu,
  TabsCustomBody,
  TabsCustomItem,
} from "ui-kit-ck-consultant";
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusSquare } from "@fortawesome/free-solid-svg-icons";

import {
  getCurrencyValue,
  getIndex,
  getNumberValue,
} from "../../utils/general";

import CriteriaDetail from "./CriteriaDetail";

import AuthContext from "../../context/AuthContext";

export default class Criteria extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: 1,
      pageLength: 30,
      folders: [],
      tabsMenu: [
        { name: "Emplacement choc", var: "dataChoc", types: [0, 1] },
        { name: "Constructeur", var: "dataBuilder", types: [0, 1, 2] },
        { name: "Garage agréé", var: "dataApproved", types: [0, 1] },
        { name: "VGE", var: "dataVGE", types: [0] },
        { name: "Genre", var: "dataKind", types: [0, 1, 2] },
        { name: "Carrosserie", var: "dataCarType", types: [0, 1, 2] },
        { name: "Nombre de chocs", var: "dataCountChoc", types: [0] },
        { name: "Année", var: "dataAge", types: [0, 1, 2] },
      ],
      tabsId: 0,
    };
  }

  static contextType = AuthContext;

  getStatusColor = (value) => {
    if (value >= 100) {
      return "bg-green";
    } else if (value === 0) {
      return "bg-blue";
    } else if (value >= 90) {
      return "bg-orange";
    } else {
      return "bg-red";
    }
  };

  openAudit = (reference) => {
    if (this.props.tabsId === 0) {
      window.open(
        `${this.context.urlForm}/audit-technique-expert-terrain.php?reference=${reference}`,
        "_blank"
      );
    } else if (this.props.tabsId === 1) {
      window.open(
        `${this.context.urlForm}/audit-technique-ead.php?reference=${reference}`,
        "_blank"
      );
    } else {
      window.open(
        `${this.context.urlForm}/audit-technique-riv-vei.php?reference=${reference}`,
        "_blank"
      );
    }
  };

  render() {
    let data = {
      dataChoc: [
        {
          text: "AR",
          values: ["7"],
          averageCost: 0,
          averagePredict: 0,
          averageDifference: 0,
          folders: [],
        },
        {
          text: "AV",
          values: ["8"],
          averageCost: 0,
          averagePredict: 0,
          averageDifference: 0,
          folders: [],
        },
        {
          text: "LG",
          values: ["1", "3", "5"],
          averageCost: 0,
          averagePredict: 0,
          averageDifference: 0,
          folders: [],
        },
        {
          text: "LD",
          values: ["2", "4", "6"],
          averageCost: 0,
          averagePredict: 0,
          averageDifference: 0,
          folders: [],
        },
        {
          text: "Ensemble",
          values: ["C"],
          averageCost: 0,
          averagePredict: 0,
          averageDifference: 0,
          folders: [],
        },
        {
          text: "Autre",
          values: [],
          averageCost: 0,
          averagePredict: 0,
          averageDifference: 0,
          folders: [],
        },
      ],

      dataBuilder: [
        {
          text: "Française",
          values: ["RENAULT", "PEUGEOT", "CITROEN"],
          averageCost: 0,
          averagePredict: 0,
          averageDifference: 0,
          folders: [],
        },
        {
          text: "Allemande",
          values: ["AUDI", "BMW", "OPEL"],
          averageCost: 0,
          averagePredict: 0,
          averageDifference: 0,
          folders: [],
        },
        {
          text: "Autre",
          values: [],
          averageCost: 0,
          averagePredict: 0,
          averageDifference: 0,
          folders: [],
        },
      ],

      dataApproved: [
        {
          text: "Oui",
          values: ["1"],
          averageCost: 0,
          averagePredict: 0,
          averageDifference: 0,
          folders: [],
        },
        {
          text: "Non",
          values: ["0"],
          averageCost: 0,
          averagePredict: 0,
          averageDifference: 0,
          folders: [],
        },
        {
          text: "Autre",
          values: [],
          averageCost: 0,
          averagePredict: 0,
          averageDifference: 0,
          folders: [],
        },
      ],
      dataVGE: [
        {
          text: "Oui",
          values: ["1"],
          averageCost: 0,
          averagePredict: 0,
          averageDifference: 0,
          folders: [],
        },
        {
          text: "Non",
          values: ["0"],
          averageCost: 0,
          averagePredict: 0,
          averageDifference: 0,
          folders: [],
        },
        {
          text: "Autre",
          values: [],
          averageCost: 0,
          averagePredict: 0,
          averageDifference: 0,
          folders: [],
        },
      ],

      dataKind: [
        {
          text: "VP",
          values: ["VP"],
          averageCost: 0,
          averagePredict: 0,
          averageDifference: 0,
          folders: [],
        },
        {
          text: "CTTE",
          values: ["CTTE"],
          averageCost: 0,
          averagePredict: 0,
          averageDifference: 0,
          folders: [],
        },
        {
          text: "Autre",
          values: [],
          averageCost: 0,
          averagePredict: 0,
          averageDifference: 0,
          folders: [],
        },
      ],
      dataCarType: [
        {
          text: "BREAK",
          values: ["BREAK"],
          averageCost: 0,
          averagePredict: 0,
          averageDifference: 0,
          folders: [],
        },
        {
          text: "FOURGON",
          values: ["FOURGON"],
          averageCost: 0,
          averagePredict: 0,
          averageDifference: 0,
          folders: [],
        },
        {
          text: "CI",
          values: ["CI%"],
          averageCost: 0,
          averagePredict: 0,
          averageDifference: 0,
          folders: [],
        },
        {
          text: "Autre",
          values: [],
          averageCost: 0,
          averagePredict: 0,
          averageDifference: 0,
          folders: [],
        },
      ],
      dataCountChoc: [
        {
          text: "1",
          values: ["1"],
          averageCost: 0,
          averagePredict: 0,
          averageDifference: 0,
          folders: [],
        },
        {
          text: "2",
          values: ["2"],
          averageCost: 0,
          averagePredict: 0,
          averageDifference: 0,
          folders: [],
        },
        {
          text: "3",
          values: ["3"],
          averageCost: 0,
          averagePredict: 0,
          averageDifference: 0,
          folders: [],
        },
        {
          text: "4",
          values: ["4"],
          averageCost: 0,
          averagePredict: 0,
          averageDifference: 0,
          folders: [],
        },
        {
          text: "5",
          values: ["5"],
          averageCost: 0,
          averagePredict: 0,
          averageDifference: 0,
          folders: [],
        },
      ],
      dataAge: [
        {
          text: "0-5 ans",
          values: ["0", "1", "2", "3", "4"],
          averageCost: 0,
          averagePredict: 0,
          averageDifference: 0,
          folders: [],
        },
        {
          text: "5-10 ans",
          values: ["5", "6", "7", "8", "9"],
          averageCost: 0,
          averagePredict: 0,
          averageDifference: 0,
          folders: [],
        },
        {
          text: "+ de 10 ans",
          values: [],
          averageCost: 0,
          averagePredict: 0,
          averageDifference: 0,
          folders: [],
        },
      ],
    };

    if (this.props.data.folders) {
      this.props.data.folders.forEach((folder) => {
        let idx = null;
        folder.chocs.map((choc) => {
          idx = getIndex(data.dataChoc, choc.substring(0, 1), "values");
          if (idx >= 0) {
            data.dataChoc[idx].folders.push(folder);
          } else {
            data.dataChoc[data.dataChoc.length - 1].folders.push(folder);
          }
          return null;
        });

        idx = getIndex(data.dataBuilder, folder.builder, "values");
        if (idx >= 0) {
          data.dataBuilder[idx].folders.push(folder);
        } else {
          data.dataBuilder[data.dataBuilder.length - 1].folders.push(folder);
        }

        idx = getIndex(data.dataApproved, folder.approved, "values");
        if (idx >= 0) {
          data.dataApproved[idx].folders.push(folder);
        }

        idx = getIndex(data.dataVGE, folder.VGE, "values");
        if (idx >= 0) {
          data.dataVGE[idx].folders.push(folder);
        } else {
          data.dataVGE[data.dataVGE.length - 1].folders.push(folder);
        }

        idx = getIndex(data.dataKind, folder.kind, "values");
        if (idx >= 0) {
          data.dataKind[idx].folders.push(folder);
        } else {
          data.dataKind[data.dataKind.length - 1].folders.push(folder);
        }

        idx = getIndex(data.dataCarType, folder.carType, "values");
        if (idx >= 0) {
          data.dataCarType[idx].folders.push(folder);
        } else {
          data.dataCarType[data.dataCarType.length - 1].folders.push(folder);
        }

        idx = getIndex(data.dataCountChoc, folder.chocs.length, "values");
        if (idx >= 0) {
          data.dataCountChoc[idx].folders.push(folder);
        } else {
          data.dataCountChoc[data.dataCountChoc.length - 1].folders.push(
            folder
          );
        }

        idx = getIndex(data.dataAge, folder.age, "values");
        if (idx >= 0) {
          data.dataAge[idx].folders.push(folder);
        } else {
          data.dataAge[data.dataAge.length - 1].folders.push(folder);
        }
      });
    }

    return (
      <>
        <TabsCustom>
          <TabsCustomHeader>
            {this.state.tabsMenu.map((element, key) => {
              if (element.types.includes(this.props.tabsId)) {
                return (
                  <TabsCustomMenu
                    disabled={this.props.isLoad}
                    key={key}
                    title={element.name}
                    active={this.state.tabsId === key}
                    onClick={() => {
                      this.setState(
                        { tabsId: key, folders: [] },
                        this.initOptions
                      );
                    }}
                  />
                );
              } else {
                return null;
              }
            })}
          </TabsCustomHeader>
          <TabsCustomBody noCard>
            <TabsCustomItem active={true}>
              {this.state.tabsMenu.map((element, key) => {
                if (this.state.tabsId === key) {
                  return (
                    <CriteriaDetail
                      format={this.props.format}
                      key={key}
                      setFolders={(folders) => this.setState({ folders })}
                      title={element.name}
                      data={data[element.var]}
                    />
                  );
                } else {
                  return null;
                }
              })}
            </TabsCustomItem>
          </TabsCustomBody>
        </TabsCustom>
        {this.state.folders.length ? (
          <Card
            className="mb-10 mb-20-xl"
            title="Dossiers"
            classNameChildren="overflow-x-auto"
          >
            <Table className="mt-20">
              <Thead>
                <Tr>
                  <Th className="nowrap">N° dossier</Th>
                  <Th className="nowrap">Expert</Th>
                  <Th className="nowrap">Date de dépôt</Th>
                  <Th className="nowrap">Marque</Th>
                  <Th className="nowrap">Modèle</Th>
                  <Th className="nowrap">Immat.</Th>
                  <Th className="nowrap">Réel</Th>
                  <Th className="nowrap">Audit</Th>
                </Tr>
              </Thead>
              <Tbody>
                {this.state.folders
                  .slice(
                    (this.state.activePage - 1) * this.state.pageLength,
                    this.state.activePage * this.state.pageLength
                  )
                  .map((folder, idx) => (
                    <Tr key={idx}>
                      <Th>{folder.reference}</Th>
                      <Td>{folder.expertName}</Td>
                      <Td>{folder.dateClosing}</Td>
                      <Td>{folder.builder}</Td>
                      <Td>{folder.model}</Td>
                      <Td>{folder.licensePlate}</Td>
                      <Td>
                        {this.props.format === "€"
                          ? getCurrencyValue(folder.cost)
                          : `${getNumberValue(folder.cost)} ${
                              this.props.format
                            }`}
                      </Td>
                      <Td className="text-center">
                        {folder.auditValue ? (
                          <Status
                            className={`${
                              folder.auditValue < 80
                                ? "bg-red"
                                : folder.auditValue >= 90
                                ? "bg-green"
                                : "bg-orange"
                            } w-100`}
                            text={`${folder.auditValue}%`}
                          />
                        ) : (
                          <ButtonIcon
                            text="Faire un audit"
                            className="blue"
                            small
                            outline
                            onClick={() => this.openAudit(folder.reference)}
                          >
                            <FontAwesomeIcon icon={faPlusSquare} />
                          </ButtonIcon>
                        )}
                      </Td>
                    </Tr>
                  ))}
              </Tbody>
            </Table>
            <Pagination
              activePage={this.state.activePage}
              itemsCountPerPage={this.state.pageLength}
              totalItemsCount={this.state.folders.length}
              pageRangeDisplayed={4}
              onChange={(activePage) => {
                this.setState({ activePage: activePage });
              }}
              firstPageText={<FontAwesomeIcon icon={faAngleDoubleLeft} />}
              lastPageText={<FontAwesomeIcon icon={faAngleDoubleRight} />}
              prevPageText={<FontAwesomeIcon icon={faChevronLeft} />}
              nextPageText={<FontAwesomeIcon icon={faChevronRight} />}
            />
          </Card>
        ) : null}
      </>
    );
  }
}
