import React from "react";
import { Col, Row } from "react-flexbox-grid";
import {
  Card,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Status,
} from "ui-kit-ck-consultant";

import Podium from "./Podium";

import { getPercentageValue } from "../../utils/general";

export default class CardPodiumTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  creatTab(data) {
    return (
      <Table
        isCSV
        getAllData={(callback) => {
          this.setState({ isCSV: true }, callback);
        }}
        csvCallback={() => this.setState({ isCSV: false })}
      >
        <Thead>
          <Tr>
            <Th className="nowrap">Classement</Th>
            <Th className="nowrap">{this.props.tableTitle}</Th>
            {this.props.isSpecial ? (
              <Th className="nowrap">Nb. dossiers</Th>
            ) : null}
            <Th className="nowrap">Résultat</Th>
            {this.props.isSpecial ? <Th className="nowrap">N/N-1</Th> : null}
          </Tr>
        </Thead>
        <Tbody>
          {data.map((value, idx) => (
            <Tr key={idx}>
              <Th className="nowrap">{value[0]}</Th>
              <Td className="w-100">{value[1]}</Td>
              {this.props.isSpecial ? (
                <Td className="nowrap">{value[3]}</Td>
              ) : null}
              <Td className="nowrap">{this.formStatus(value[2])}</Td>
              {this.props.isSpecial ? (
                <Td className="nowrap">
                  {this.getStatusEvolution(value[4], value[5])}
                </Td>
              ) : null}
            </Tr>
          ))}
        </Tbody>
      </Table>
    );
  }

  getStatusEvolution(value, reverse = false) {
    if ((value > 0 && !reverse) || (reverse && value < 0)) {
      return (
        <Status
          text={getPercentageValue(value, 2)}
          className={"bg-red-light w-100 black"}
        />
      );
    } else if ((value < 0 && !reverse) || (reverse && value > 0)) {
      return (
        <Status
          text={getPercentageValue(value, 2)}
          className={"bg-green-light w-100 black"}
        />
      );
    } else {
      return (
        <Status
          text={getPercentageValue(value, 2)}
          className={"bg-blue-light w-100 black"}
        />
      );
    }
  }

  formStatus(value) {
    let color = "";
    if (this.props.isColor) {
      if (value >= 100) {
        color = "bg-green-light w-100 black";
      } else if (value >= 90) {
        color = "bg-orange-light w-100 black";
      } else {
        color = "bg-red-light w-100 black";
      }
    }

    let format = "";
    if (this.props.format === "currency") {
      format = "€";
    } else if (this.props.format === "percentage") {
      format = "%";
    }

    return <Status text={value + ` ${format}`} className={color} />;
  }

  getData(data, key) {
    if (data && data.length > key) {
      return {
        value: data[key].value,
        name: data[key].name,
        total: data[key].total,
        year: data[key].year,
        reverse: data[key].reverse,
      };
    } else {
      return {
        value: null,
        name: null,
        total: null,
        year: null,
        reverse: null,
      };
    }
  }

  render() {
    let data = [];
    for (
      let index = this.state.isCSV ? 0 : 3;
      index < (this.props.length || this.props.data.length);
      index++
    ) {
      data.push([
        index + 1,
        this.getData(this.props.data, index).name,
        this.getData(this.props.data, index).value,
        this.getData(this.props.data, index).total,
        this.getData(this.props.data, index).year,
        this.getData(this.props.data, index).reverse,
      ]);
    }

    return (
      <Card
        title={this.props.title}
        className="mb-10 mb-30-xl"
        isLoader={this.props.isLoad}
      >
        {this.props.other ? (
          <p style={{ fontSize: "10px" }}>Genre : VP et CTTE</p>
        ) : null}
        <Row>
          <Col xs={4} className={"d-flex"}>
            <Podium
              className={"mt-auto ml-auto mr-auto mb-30 "}
              height={70}
              dataNumber={this.getData(this.props.data, 1).value}
              subDataNumber={this.getData(this.props.data, 1).total}
              dataText={this.getData(this.props.data, 1).name}
              rank={2}
              format={this.props.format}
              evolution={this.getStatusEvolution(
                this.getData(this.props.data, 1).year,
                this.getData(this.props.data, 1).reverse
              )}
            />
          </Col>
          <Col xs={4} className={"d-flex"}>
            <Podium
              className={"mt-auto ml-auto mr-auto mb-30 "}
              height={90}
              subDataNumber={this.getData(this.props.data, 0).total}
              dataNumber={this.getData(this.props.data, 0).value}
              dataText={this.getData(this.props.data, 0).name}
              rank={1}
              format={this.props.format}
              evolution={this.getStatusEvolution(
                this.getData(this.props.data, 0).year,
                this.getData(this.props.data, 0).reverse
              )}
            />
          </Col>
          <Col xs={4} className={"d-flex"}>
            <Podium
              className={"mt-auto ml-auto mr-auto mb-30 "}
              height={50}
              dataNumber={this.getData(this.props.data, 2).value}
              subDataNumber={this.getData(this.props.data, 2).total}
              dataText={this.getData(this.props.data, 2).name}
              rank={3}
              format={this.props.format}
              evolution={this.getStatusEvolution(
                this.getData(this.props.data, 2).year,
                this.getData(this.props.data, 2).reverse
              )}
            />
          </Col>
          <Col xs={12}>{this.creatTab(data)}</Col>
        </Row>
      </Card>
    );
  }
}
