import React from "react";
import {
  Card,
  Nav,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Loader,
  Pagination,
  Button,
  FormInput,
} from "ui-kit-ck-consultant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import FadeIn from "react-fade-in";
import { Row, Col } from "react-flexbox-grid";

import AuthContext from "../../context/AuthContext";

import axiosConfig from "../../utils/axiosConfig";

export default class SearchPrincipal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoad: false,
      principals: [],
      activePage: 1,
      pageLength: 50,
    };
  }

  static contextType = AuthContext;

  componentDidMount() {
    this.getPrincipal();
  }

  getPrincipal = () => {
    this.setState({ isLoad: true }, () => {
      axiosConfig("search/principals", {
        data: {
          search: this.state.search,
        },
      })
        .then((res) => {
          if (res.data.success === true) {
            this.setState({
              isLoad: false,
              principals: res.data.data,
            });
          } else {
            this.setState(
              {
                isLoad: false,
                principals: [],
              },
              () => alert("Pas de dossier")
            );
          }
        })
        .catch(() => {
          this.setState(
            {
              isLoad: false,
              principals: [],
            },
            () => alert("Pas de dossier")
          );
        });
    });
  };

  render() {
    return (
      <>
        <Nav className="mb-10 mb-30-xl" title="Recherche mandant" />
        <FadeIn>
          <Card title="Liste des mandants">
            <Row>
              <Col xs={12} md={4}>
                <FormInput
                  className="mb-10"
                  value={this.state.search}
                  onChange={(e) => this.setState({ search: e.target.value })}
                />
              </Col>
              <Col xs={12} md={4}>
                <Button
                  className="mb-10"
                  text="Rechercher"
                  onClick={() => this.getPrincipal()}
                />
              </Col>
            </Row>
            <Table>
              <Thead>
                <Tr>
                  <Th>#</Th>
                  <Th>Nom</Th>
                  <Th>Codes</Th>
                  <Th>Adresse 1</Th>
                  <Th>Adresse 2</Th>
                  <Th>Code postal</Th>
                  <Th>Ville</Th>
                  <Th>Téléphone</Th>
                  <Th>Fax</Th>
                  <Th>E-mail</Th>
                </Tr>
              </Thead>
              <Tbody>
                {this.state.principals
                  .slice(
                    (this.state.activePage - 1) * this.state.pageLength,
                    this.state.activePage * this.state.pageLength
                  )
                  .map((element, idx) => (
                    <Tr>
                      <Td>{idx + 1}</Td>
                      <Td>{element.name}</Td>
                      <Td>{element.code}</Td>
                      <Td>{element.address1}</Td>
                      <Td>{element.address2}</Td>
                      <Td>{element.postalCode}</Td>
                      <Td>{element.city}</Td>
                      <Td>{element.phone}</Td>
                      <Td>{element.fax}</Td>
                      <Td>{element.email}</Td>
                    </Tr>
                  ))}
              </Tbody>
            </Table>
            <Pagination
              className="mt-10"
              activePage={this.state.activePage}
              itemsCountPerPage={this.state.pageLength}
              totalItemsCount={this.state.principals.length}
              pageRangeDisplayed={4}
              onChange={(activePage) => {
                this.setState({ activePage: activePage });
              }}
              firstPageText={<FontAwesomeIcon icon={faAngleDoubleLeft} />}
              lastPageText={<FontAwesomeIcon icon={faAngleDoubleRight} />}
              prevPageText={<FontAwesomeIcon icon={faChevronLeft} />}
              nextPageText={<FontAwesomeIcon icon={faChevronRight} />}
            />
          </Card>
        </FadeIn>
        <Loader show={this.state.isLoad} />
      </>
    );
  }
}
