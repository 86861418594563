import React, { Component } from "react";
import { Card } from "ui-kit-ck-consultant";
import { Scatter } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
} from "chart.js";
import { Row, Col } from "react-flexbox-grid";

import {
  getCurrencyValue,
  getNumberValue,
  getPercentageValue,
} from "../../utils/general";
import { colors } from "../../utils/colors";

export default class Chart extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getData = (data_tmp) => {
    let data = {
      datasets: [
        {
          label: "Perfom +",
          data: [],
          backgroundColor: colors["green"][0],
          borderColor: colors["green"][0],
        },
        {
          label: "Perform -",
          data: [],
          backgroundColor: colors["orange"][0],
          borderColor: colors["orange"][0],
        },
        {
          type: "line",
          label: "Moyenne",
          data: [],
          borderColor: colors["blue"][0],
          pointRadius: 0,
        },
      ],
    };

    let min = 100;
    let max = 100;

    data_tmp.forEach((element, idx) => {
      if (element.averageCost) {
        let current = element.averageDifference;
        if (current > 100) {
          if (current > max) {
            max = current;
          }
          data.datasets[0].data.push({
            x: current,
            y: element.averageCost,
            name: element.name,
            predict: element.averagePredict,
            difference: element.averageDifference,
            count: element.count,
          });
        } else {
          if (current < min) {
            min = current;
          }
          data.datasets[1].data.push({
            x: current,
            y: element.averageCost,
            name: element.name,
            predict: element.averagePredict,
            difference: element.averageDifference,
            count: element.count,
          });
        }
      }
    });

    data.datasets[2].data.push({
      x: min,
      y: this.props.data.averageCost,
    });
    data.datasets[2].data.push({
      x: max,
      y: this.props.data.averageCost,
    });

    return data;
  };

  render() {
    ChartJS.register(LinearScale, PointElement, LineElement, Tooltip, Legend);

    const format = this.props.format;

    const options = {
      scales: {
        y: {
          grid: {
            display: false,
          },
          ticks: {
            callback: function (value, index, ticks) {
              return format === "€"
                ? getCurrencyValue(value, 0)
                : `${getNumberValue(value, 0)} ${format}`;
            },
          },
        },
        x: {
          grid: {
            display: false,
          },
          ticks: {
            callback: function (value, index, ticks) {
              return `${value}%`;
            },
          },
        },
      },
      plugins: {
        tooltip: {
          callbacks: {
            label: function (context) {
              return [
                context.raw.name,
                `Réel : ${
                  format === "€"
                    ? getCurrencyValue(context.raw.y)
                    : `${getNumberValue(context.raw.difference)} ${format}`
                }`,
                `Prédit : ${
                  format === "€"
                    ? getCurrencyValue(context.raw.predict)
                    : `${getNumberValue(context.raw.predict)} ${format}`
                }`,
                `Perf. : ${getPercentageValue(context.raw.difference)}`,
                `Nombre : ${context.raw.count}`,
              ];
            },
          },
        },
      },
    };

    return (
      <Card className="mb-10 mb-20-xl" title="Graphiques">
        <Row>
          <Col xs={12} md={12} lg={4} className="mb-20">
            <h4 className="text-center m-0">Région</h4>
            <Scatter
              options={options}
              data={this.getData(this.props.dataRegion)}
            />
          </Col>
          <Col xs={12} md={12} lg={4} className="mb-20">
            <h4 className="text-center m-0">Agence</h4>
            <Scatter
              options={options}
              data={this.getData(this.props.dataAgency)}
            />
          </Col>
          <Col xs={12} md={12} lg={4} className="mb-20">
            <h4 className="text-center m-0">Expert</h4>
            <Scatter
              options={options}
              data={this.getData(this.props.dataExpert)}
            />
          </Col>
        </Row>
      </Card>
    );
  }
}
