import React, { Component } from "react";
import { Row, Col } from "react-flexbox-grid";
import {
  Card,
  Nav,
  Table,
  Thead,
  Tbody,
  Tr,
  Td,
  Th,
  PieChart,
  FormInput,
  Button,
  Loader,
} from "ui-kit-ck-consultant";

import axiosConfig from "../../../utils/axiosConfig";
import { getNumberValue, getValue } from "../../../utils/general";

import scoreA from "../../../images/scoreA.svg";
import scoreB from "../../../images/scoreB.svg";
import scoreC from "../../../images/scoreC.svg";
import scoreD from "../../../images/scoreD.svg";
import scoreE from "../../../images/scoreE.svg";

function getScoreImage(score) {
  if (score > 50) {
    return scoreA;
  } else if (score > 40) {
    return scoreB;
  } else if (score > 30) {
    return scoreC;
  } else if (score > 20) {
    return scoreD;
  } else {
    return scoreE;
  }
}

export default class FolderProjectE extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      reference: "",
      isLoad: false,
    };
  }

  getFolder() {
    if (this.state.reference) {
      this.setState({ isLoad: true }, () => {
        axiosConfig("project-e/get-folder", {
          data: {
            reference: this.state.reference,
          },
        }).then((res) => {
          console.log(res.data);
          if (res.data.success === true) {
            this.setState({
              data: res.data.data,
              isLoad: false,
            });
          } else {
            this.setState({
              data: null,
              isLoad: false,
            });
          }
        });
      });
    }
  }

  componentDidMount() {
    this.getFolder();
  }

  render() {
    return (
      <>
        <Nav title="Analyse dossier" className="mb-10 mb-20-xl" />
        <Card
          className="mb-10 mb-20-xl"
          title="Recherche"
          classNameChildren="d-flex"
        >
          <FormInput
            title="Référence"
            value={this.state.reference}
            onChange={(e) => this.setState({ reference: e.target.value })}
          />
          <Button
            className="mt-auto ml-10"
            text="Rechercher"
            onClick={() => this.getFolder()}
          />
        </Card>
        {this.state.data ? (
          <>
            <Row>
              <Col xs={12} md={7} className={"col_full_height"}>
                <Card className="mb-10 mb-20-xl" title="Vehicle Information">
                  <Table>
                    <Tbody>
                      <Tr>
                        <Th>VIN</Th>
                        <Td>{this.state.data.vehicle.VIN}</Td>
                      </Tr>
                      <Tr>
                        <Th>Builder</Th>
                        <Td>{this.state.data.vehicle.Constructeur}</Td>
                      </Tr>
                      <Tr>
                        <Th>Model</Th>
                        <Td>{this.state.data.vehicle.Modele}</Td>
                      </Tr>
                      <Tr>
                        <Th>Finishing</Th>
                        <Td>{this.state.data.vehicle.Finition}</Td>
                      </Tr>
                      <Tr>
                        <Th>Type Mine</Th>
                        <Td>{this.state.data.vehicle.Type_Mine}</Td>
                      </Tr>
                      <Tr>
                        <Th>Car Weight</Th>
                        <Td>{this.state.data.vehicle.Poids_Tot_Charge}</Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </Card>
              </Col>
              <Col xs={12} md={5} className={"col_full_height"}>
                <Card
                  className="mb-10 mb-20-xl"
                  title="Global emissions"
                  classNameChildren="text-center"
                >
                  <div
                    style={{
                      display: "inline-flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "160px",
                      width: "160px",
                      borderRadius: "110px",
                      backgroundColor: "#bcd45c",
                      flexDirection: "column",
                      gap: "10px",
                    }}
                  >
                    <p style={{ margin: 0, fontSize: "30px" }}>
                      {getValue(this.state.data.total)}
                    </p>
                    <p style={{ margin: 0, fontSize: "10px" }}>(kg CO2e)</p>
                  </div>
                  <p style={{ fontSize: "10px" }} className="mb-0">
                    *Average emissions of the cases where
                  </p>
                  <p style={{ fontSize: "10px" }} className="mt-0">
                    assigned <b>GROUPE LANG</b>: 68,26 kg CO2e
                  </p>
                </Card>
              </Col>
              <Col xs={12}>
                <Card className="mb-10 mb-20-xl" title="Parts">
                  <Table>
                    <Thead>
                      <Tr>
                        <Th>Name</Th>
                        <Th>Quantity</Th>
                        <Th>Paint</Th>
                        <Th>Operation</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {this.state.data.parts.map((element, idx) => (
                        <Tr key={idx}>
                          <Td>{element.Libelle}</Td>
                          <Td>{element.Qte}</Td>
                          <Td>{element.Operation_Peint}</Td>
                          <Td>{element.Qualif_Operation}</Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </Card>
              </Col>
              <Col xs={12}>
                <Row>
                  <Col xs={12} md={5}>
                    <Card className="mb-10 mb-20-xl" title="Hours">
                      <Table>
                        <Tbody>
                          {this.state.data.hours.map((element, idx) => (
                            <Tr key={idx}>
                              <Th>{element.name}</Th>
                              <Td>{element.value}</Td>
                            </Tr>
                          ))}
                        </Tbody>
                      </Table>
                    </Card>
                    <Card className="mb-10 mb-20-xl" title="Emission (kg CO2e)">
                      <Table>
                        <Tbody>
                          <Tr>
                            <Th>Replacement of Reused Parts</Th>
                            <Td>{getValue(this.state.data.PRE)}</Td>
                          </Tr>
                          <Tr>
                            <Th>Replacement of New Parts and consumables</Th>
                            <Td>{getValue(this.state.data.changePart)}</Td>
                          </Tr>
                          <Tr>
                            <Th>Workforce</Th>
                            <Td>{getValue(this.state.data.repairPart)}</Td>
                          </Tr>
                          <Tr>
                            <Th>Paint Ingredients</Th>
                            <Td>{getValue(this.state.data.paintPart)}</Td>
                          </Tr>
                          <Tr>
                            <Th>Client Travel</Th>
                            <Td>{getValue(this.state.data.travelingClient)}</Td>
                          </Tr>
                          <Tr>
                            <Th>Expert Travel</Th>
                            <Td>{getValue(this.state.data.travelingExpert)}</Td>
                          </Tr>
                          <Tr>
                            <Th>Offices + IT</Th>
                            <Td>{getValue(this.state.data.local)}</Td>
                          </Tr>
                        </Tbody>
                      </Table>
                    </Card>
                  </Col>
                  <Col xs={12} md={7}>
                    <Card
                      className="mb-10 mb-20-xl"
                      title="Distribution of emissions"
                      classNameChildren="d-flex h-100"
                    >
                      <div className="m-auto">
                        <PieChart
                          digits={0}
                          title={""}
                          legendDisplay={true}
                          height={360}
                          data={[
                            {
                              labels: "Replacement of Reused Parts",
                              data: this.state.data.PRE,
                              color: "#4FBC9D",
                            },
                            {
                              labels:
                                "Replacement of New Parts and consumables",
                              data: this.state.data.changePart,
                              color: "#56C3EC",
                            },
                            {
                              labels: "Workforce",
                              data: this.state.data.repairPart,
                              color: "#E85D7D",
                            },
                            {
                              labels: "Paint Ingredients",
                              data: this.state.data.paintPart,
                              color: "#F19A3E",
                            },
                            {
                              labels: "Client Travel",
                              data: this.state.data.travelingClient,
                              color: "#4AD95F",
                            },
                            {
                              labels: "Expert Travel",
                              data: this.state.data.travelingExpert,
                              color: "#E6DB3F",
                            },
                            {
                              labels: "Offices + IT",
                              data: this.state.data.local,
                              color: "#C45AF6",
                            },
                          ]}
                        />
                      </div>
                    </Card>
                    <Card
                      className="mb-10 mb-20-xl"
                      title="Conclusion"
                      classNameChildren="d-flex h-100 flex-column"
                    >
                      <div class="score-image">
                        <img
                          src={getScoreImage(this.state.data.score)}
                          alt="Score"
                        />
                      </div>
                      <div>
                        <b>
                          {getNumberValue(this.state.data.savingPRE, 2)} kg CO2e
                        </b>
                        <span> CO2e Saved by Replacing with Reused Parts</span>
                      </div>
                      <div>
                        <b>
                          {getNumberValue(this.state.data.savingRepair, 2)} kg
                          CO2e
                        </b>
                        <span> CO2e Saved by Repaired Parts</span>
                      </div>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          </>
        ) : null}
        <Loader show={this.state.isLoad} />
      </>
    );
  }
}
