import React from "react";
import {
  Card,
  FormSelect,
  FormSelectMultiples,
  FormCheckbox,
  FormInput,
  FormRadio,
  Button,
  Calendar,
} from "ui-kit-ck-consultant";
import { Row, Col } from "react-flexbox-grid";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync } from "@fortawesome/free-solid-svg-icons";

import axiosConfig from "../../utils/axiosConfig";
import { getUsers } from "../../utils/general";
import getYears from "../../utils/years";

import AuthContext from "../../context/AuthContext";

export default class ShowOptionsNGRA extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // permission
      isNetwork: false,
      isGroup: false,
      isRegion: false,
      isAgency: false,
      // options
      yearOptions: getYears(this.props.minYear, this.props.maxYear),
      networkOptions: [],
      groupOptions: [],
      regionOptions: [],
      agencyOptions: [],
      userOptions: [],
      interviewOptions: [],
      simOptions: [],
      builderOptions: [
        { text: "Tous", value: 0 },
        { text: "Française", value: 1 },
        { text: "Allemande", value: 2 },
        { text: "Autre", value: 3 },
      ],
      notBlocked: this.props.notBlocked ? this.props.notBlocked : [],
      statusFolderOptions: [
        { text: "Tous", value: 0 },
        { text: "En attente de RDV", value: 1 },
        { text: "Expertise à venir", value: 2 },
        { text: "Expertisé", value: 3 },
        { text: "Clôturé", value: 4 },
      ],
      // checkData
      isYearData: true,
      isNetworkData: false,
      isGroupData: false,
      isRegionData: false,
      isAgencyData: false,
      isUserData: false,
      isInterviewData: false,
      isSimData: false,
      // value
      year: moment().format("YYYY"),
      date: moment().format("YYYY-01-01"),
      dateSinisterStart: moment().format("YYYY-01-01"),
      dateSinisterEnd: moment().format("YYYY-01-01"),
      dateStart: moment().format("YYYY-01-01"),
      dateEnd: moment().format("YYYY-MM-DD"),
      network: 0,
      group: 0,
      region: 0,
      agency: 0,
      agencyMultiples: [],
      user: 0,
      userMultiples: [],
      company: 0,
      companyMultiples: [],
      natureEvent: {},
      natureExpertise: {},
      companies: {},
      fiscalPower: {},
      kindOptions: [
        "VP",
        "CTTE",
        "4X4",
        "AC",
        "AG",
        "AGRI",
        "AGRICOLE",
        "AMBULANCE",
        "AUTO CARAV",
        "BA",
        "BC",
        "BEN AMO",
        "BENNE",
        "BOM",
        "BROYEUR",
        "CAM",
        "CAMION",
        "CARA",
        "CARAVANE",
        "CHARGEUR",
        "CITROEN",
        "CL",
        "CO",
        "CP",
        "CTE",
        "CTTT",
        "CY",
        "CYC1",
        "CYCL",
        "CYCLE",
        "CYCLO",
        "CYCLOMOTEU",
        "DI",
        "DIV",
        "DIVERS",
        "DL",
        "EDPM",
        "ENGIN",
        "EPAREUSE",
        "FAUCHEUSE",
        "FE",
        "FIAT",
        "FO",
        "FTRO",
        "FVVP",
        "GRUE",
        "IN",
        "JA",
        "KTM",
        "M1",
        "MA",
        "MAGA",
        "MAT AG",
        "MAT AGR",
        "MAT AGRE",
        "MAT FOREST",
        "MAT LEVAGE",
        "MAT NEIGE",
        "MAT TP",
        "MAT TRACT",
        "MATA GR",
        "MIA",
        "MIAR",
        "MOIS BATT",
        "MOT3",
        "MOTO",
        "MTL",
        "1,00 MTL",
        "2,00 MTL",
        "3,00 MTL",
        "MTSP",
        "MTT1",
        "MTT2",
        "MTTE",
        "MTTL",
        "NACELLE TÉ",
        "PICK UP",
        "PL",
        "QLEM",
        "QLM",
        "QM",
        "RE",
        "REA",
        "REM",
        "REMORQIE",
        "REMORQUE",
        "RESP",
        "RETC",
        "RM",
        "SANS",
        "SEMOIR",
        "SERA",
        "SERM",
        "SOLO",
        "SRAT",
        "SREA",
        "SREM",
        "SRSP",
        "SRTC",
        "TCP",
        "TM",
        "TP",
        "TQM",
        "TR",
        "TRA",
        "TRAC",
        "TRACT 2",
        "TRACTEUR",
        "TRAM",
        "TRAMWAY",
        "TROTINETTE",
        "TRR",
        "TRTE",
        "VAE",
        "VAPS",
        "VASP",
        "VDVP",
        "VELO",
        "VELO ELECT",
        "VG",
        "VL",
        "VO",
        "VOIERIE",
        "VOIRIE",
        "VT",
        "VVP",
        "VYYR",
        "X",
      ],
      kind: this.props.defaultKind || [],
      brand: {},
      projetEBrand: [],
      interview: 0,
      sim: 0,
      builder: 0,
      garage: "",
      garageApproved: "all",
      clientZipCode: "",
      statusFolder: "all",
      isDetails: false,
      isCalendar: false,
      dateCalendar: null,
      nameCalendar: null,
      minDateCalendar: null,
      maxDateCalendar: null,
      isChange: true,
    };
  }

  static contextType = AuthContext;

  handleInputChangeText = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState(
      {
        [name]: value.includes("-") ? value : parseInt(value),
      },
      () => {
        if (["network", "group", "region", "agency"].includes(name)) {
          this.setState(
            {
              userOptions: [],
              interviewOptions: [],
              simOptions: [],
              isUserData: false,
              isInterviewData: false,
              isSimData: false,
            },
            () => {
              if (name === "network") {
                this.setState(
                  {
                    group: null,
                    region: null,
                    agency: null,
                    user: null,
                  },
                  () => this.getGroups(this.checkInput)
                );
              } else if (name === "group") {
                this.setState(
                  {
                    region: null,
                    agency: null,
                    user: null,
                  },
                  () => this.getRegions(this.checkInput)
                );
              } else if (name === "region") {
                this.setState(
                  {
                    agency: null,
                    user: null,
                  },
                  () => this.getAgencies(this.checkInput)
                );
              } else if (name === "agency") {
                if (this.props.isSim) {
                  this.getSims(this.checkInput);
                } else {
                  this.setState(
                    {
                      user: null,
                    },
                    () => this.getUsers(this.checkInput)
                  );
                }
              } else this.handleDataChange();
            }
          );
        } else if (name === "year") {
          if (this.state.user && this.props.isInterview)
            this.getInterviews(this.checkInput);
          else if (this.state.agency && this.props.isSim)
            this.getSims(this.checkInput);
          else this.handleDataChange();
        } else {
          if (name === "user") {
            if (!this.state.user) {
              this.setState(
                {
                  interviewOptions: [],
                  isInterviewData: false,
                },
                () => this.handleDataChange()
              );
            } else if (this.props.isInterview) {
              this.getInterviews(this.checkInput);
            } else {
              this.handleDataChange();
            }
          } else if (name === "date" && this.props.isDateStart) {
            this.setState(
              {
                dateStart: this.state.date,
              },
              () => this.handleDataChange()
            );
          } else this.handleDataChange();
        }
      }
    );
  };

  handleCheckboxChange = (e) => {
    let { name, checked } = e.target;
    name = name.split("-");
    let tmpData = this.state[name[0]];
    tmpData[name[1]] = checked;
    this.setState(
      {
        [name[0]]: tmpData,
      },
      this.handleDataChange()
    );
  };

  handleRadioChange = (e) => {
    let { name, value } = e.target;
    this.setState(
      {
        [name]: value,
      },
      this.handleDataChange()
    );
  };

  handleDataChange = (isChange = !this.props.isChange) => {
    if (isChange) {
      let natureEvent = [];
      Object.keys(this.state.natureEvent).forEach((key) => {
        if (this.state.natureEvent[key]) {
          natureEvent.push(key);
        }
      });
      if (natureEvent.length === Object.keys(this.state.natureEvent).length) {
        natureEvent = [];
      }

      let natureExpertise = [];
      Object.keys(this.state.natureExpertise).forEach((key) => {
        if (this.state.natureExpertise[key]) {
          natureExpertise.push(key);
        }
      });
      if (
        natureExpertise.length ===
        Object.keys(this.state.natureExpertise).length
      ) {
        natureExpertise = [];
      }

      let companies = [];
      Object.keys(this.state.companies).forEach((key) => {
        if (this.state.companies[key]) {
          companies.push(key);
        }
      });
      if (companies.length === Object.keys(this.state.companies).length) {
        companies = [];
      }

      let fiscalPower = [];
      Object.keys(this.state.fiscalPower).forEach((key) => {
        if (this.state.fiscalPower[key]) {
          fiscalPower.push(parseInt(key));
        }
      });
      if (fiscalPower.length === Object.keys(this.state.fiscalPower).length) {
        fiscalPower = [];
      }

      let brand = [];
      Object.keys(this.state.brand).forEach((key) => {
        if (this.state.brand[key]) {
          brand.push(key);
        }
      });
      if (brand.length === Object.keys(this.state.brand).length) {
        brand = [];
      }

      this.setState(
        {
          isChange: false,
        },
        () => {
          this.props.onDataChange({
            year: this.state.year,
            date: this.state.date,
            dateSinisterStart: this.state.dateSinisterStart,
            dateSinisterEnd: this.state.dateSinisterEnd,
            dateStart: this.state.dateStart,
            dateEnd: this.state.dateEnd,
            network: this.state.network ? this.state.network : null,
            group: this.state.group ? this.state.group : null,
            region: this.state.region ? this.state.region : null,
            agency: this.state.agency ? this.state.agency : null,
            agencyMultiples: this.state.agencyMultiples
              ? this.state.agencyMultiples
              : [],
            user: this.state.user ? this.state.user : null,
            userMultiples: this.state.userMultiples
              ? this.state.userMultiples
              : [],
            company: this.state.company ? this.state.company : null,
            companyMultiples: this.state.companyMultiples
              ? this.state.companyMultiples
              : [],
            interview: this.state.interview ? this.state.interview : null,
            sim: this.state.sim ? this.state.sim : null,
            statusFolder: this.state.statusFolder
              ? this.state.statusFolder
              : null,
            natureEvent,
            natureExpertise,
            companies,
            fiscalPower,
            kind: this.state.kind ? this.state.kind : [],
            brand,
            projetEBrand: this.state.projetEBrand,
            garage: this.state.garage ? this.state.garage : null,
            garageApproved: this.state.garageApproved
              ? this.state.garageApproved
              : null,
            clientZipCode: this.state.clientZipCode
              ? this.state.clientZipCode
              : null,
            builder: this.state.builder,
          });
        }
      );
    } else {
      this.setState({ isChange: true });
    }
  };

  componentDidMount() {
    let tmpNatureEvent = {};
    this.context.natureEventOptions.forEach((element) => {
      if (
        !this.props.defaultNatureEvent ||
        this.props.defaultNatureEvent.includes(element.value)
      ) {
        tmpNatureEvent[element.value] = true;
      } else {
        tmpNatureEvent[element.value] = false;
      }
    });

    let tmpNatureExpertise = {};
    this.context.natureExpertiseOptions.forEach((element) => {
      if (
        !this.props.defaultNatureExpertise ||
        this.props.defaultNatureExpertise.includes(element.value)
      ) {
        tmpNatureExpertise[element.value] = true;
      } else {
        tmpNatureExpertise[element.value] = false;
      }
    });

    let tmpCompanies = {};
    this.context.companyOptions.forEach((element) => {
      if (
        !this.props.defaultCompanies ||
        this.props.defaultCompanies.includes(element.value)
      ) {
        tmpCompanies[element.value] = true;
      } else {
        tmpCompanies[element.value] = false;
      }
    });

    let tmpFiscalPower = {};
    this.context.fiscalPowerOptions.forEach((element) => {
      if (
        !this.props.defaultFiscalPower ||
        this.props.defaultFiscalPower.includes(element)
      ) {
        tmpFiscalPower[element] = true;
      } else {
        tmpFiscalPower[element] = false;
      }
    });

    let tmpBrand = {};
    this.context.brandOptions.forEach((element) => {
      if (
        !this.props.defaultBrand ||
        this.props.defaultBrand.includes(element)
      ) {
        tmpBrand[element] = true;
      } else {
        tmpBrand[element] = false;
      }
    });

    // check permission

    this.setState(
      {
        natureEvent: tmpNatureEvent,
        natureExpertise: tmpNatureExpertise,
        companies: tmpCompanies,
        fiscalPower: tmpFiscalPower,
        brand: tmpBrand,
        isNetwork:
          this.context.network && !this.checkForce("isForceNetwork")
            ? false
            : true,
        isGroup:
          this.context.group && !this.checkForce("isForceGroup") ? false : true,
        isRegion:
          this.context.region && !this.checkForce("isForceRegion")
            ? false
            : true,
        isAgency:
          this.context.agency &&
          !this.checkForce("isForceAgency") &&
          ![1, 2].includes(this.context.role)
            ? false
            : true,
        network:
          this.context.network &&
          (!this.checkForce("isForceNetwork") || this.checkPreload("network"))
            ? this.context.network
            : 0,
        group:
          this.context.group &&
          (!this.checkForce("isForceGroup") || this.checkPreload("group"))
            ? this.context.group
            : 0,
        region:
          this.context.region &&
          (!this.checkForce("isForceRegion") || this.checkPreload("region"))
            ? this.context.region
            : 0,
        agency:
          this.context.agency &&
          (!this.checkForce("isForceAgency") || this.checkPreload("agency"))
            ? this.context.agency
            : 0,
        yearOptions: this.props.yearOptions
          ? this.props.yearOptions
          : getYears(this.props.minYear, this.props.maxYear),
      },
      () => {
        // init options
        if (!this.context.network || this.checkForce("isForceNetwork"))
          this.getNetworks(this.checkInput, true);
        else if (!this.context.group || this.checkForce("isForceGroup"))
          this.getGroups(this.checkInput, true);
        else if (!this.context.region || this.checkForce("isForceRegion"))
          this.getRegions(this.checkInput, true);
        else if (
          !this.context.agency ||
          this.checkForce("isForceAgency") ||
          [1, 2].includes(this.context.role)
        )
          this.getAgencies(this.checkInput, true);
        else if (this.props.isUser) this.getUsers(this.checkInput, true);
        else if (this.props.isSim) this.getSims(this.checkInput);
      }
    );
  }

  checkForce = (key) => {
    let result = false;

    if (this.props.visibilityType) {
      result = this.props.visibilityType[this.context.type][key];
    }
    if (this.context.role && this.props.visibilityRole) {
      result = this.props.visibilityRole[this.context.role][key];
    }

    return result;
  };

  checkPreload = (key) => {
    let result = false;

    if (this.props.preloadType) {
      if (
        key === "network" &&
        ["network", "group", "region", "agency"].includes(
          this.props.preloadType[this.context.type]
        )
      ) {
        result = true;
      } else if (
        key === "group" &&
        ["group", "region", "agency"].includes(
          this.props.preloadType[this.context.type]
        )
      ) {
        result = true;
      } else if (
        key === "region" &&
        ["region", "agency"].includes(this.props.preloadType[this.context.type])
      ) {
        result = true;
      } else if (
        key === "agency" &&
        ["agency"].includes(this.props.preloadType[this.context.type])
      ) {
        result = true;
      }
    }
    if (this.context.role && this.props.preloadRole) {
      if (
        key === "network" &&
        ["network", "group", "region", "agency"].includes(
          this.props.preloadRole[this.context.role]
        )
      ) {
        result = true;
      } else if (
        key === "group" &&
        ["group", "region", "agency"].includes(
          this.props.preloadRole[this.context.role]
        )
      ) {
        result = true;
      } else if (
        key === "region" &&
        ["region", "agency"].includes(this.props.preloadRole[this.context.role])
      ) {
        result = true;
      } else if (
        key === "agency" &&
        ["agency"].includes(this.props.preloadRole[this.context.role])
      ) {
        result = true;
      }
    }

    return result;
  };

  checkLimitUser = () => {
    let result = false;

    if (this.props.limitUserType) {
      result = this.props.limitUserType[this.context.type];
    }
    if (this.context.role && this.props.limitUserType) {
      result = this.props.limitUserRole[this.context.role];
    }

    return result;
  };

  checkInput = () => {
    let check = {
      isNetwork: true,
      isGroup: true,
      isRegion: true,
      isAgency: true,
      isUser: true,
      isCompany: true,
      isInterview: true,
      isSim: true,
    };

    if (
      (!this.context.network || this.checkForce("isForceNetwork")) &&
      !this.state.isNetworkData
    )
      check.isNetwork = false;
    if (
      (!this.context.group || this.checkForce("isForceGroup")) &&
      !this.state.isGroupData
    )
      check.isGroup = false;
    if (
      (!this.context.region || this.checkForce("isForceRegion")) &&
      !this.state.isRegionData
    )
      check.isRegion = false;
    if (
      (!this.context.agency || this.checkForce("isForceAgency")) &&
      !this.state.isAgencyData
    ) {
      check.isAgency = false;
    }
    if (this.state.agency && this.props.isUser && !this.state.isUserData)
      check.isUser = false;
    if (this.props.isInterview && !this.state.isInterviewData)
      check.isInterview = false;
    if (this.props.isSim && !this.state.isSimData) check.isSim = false;

    let finalResult = true;
    for (const key in check) {
      const element = check[key];
      if (!element) finalResult = false;
    }

    if (finalResult) this.handleDataChange();
  };

  // start - get data

  getNetworks = (callback, isInit = false) => {
    let networkOptions = [{ value: 0, text: "Tous les réseaux" }];
    axiosConfig("/general/get-networks").then((res) => {
      if (res.data.success === true) {
        networkOptions = networkOptions.concat(res.data.networks);
      }
      this.setState(
        {
          network: isInit
            ? !this.checkForce("isForceNetwork") || this.checkPreload("network")
              ? this.context.network
              : 0
            : this.state.network,
          networkOptions,
          isNetworkData: true,
        },
        () => {
          this.getGroups(callback, isInit);
        }
      );
    });
  };

  getGroups = (callback, isInit = false) => {
    let groupOptions = [{ value: 0, text: "Tous les groupes" }];
    if (!this.context.network && !this.state.network) {
      this.setState(
        {
          group: isInit ? this.context.group : this.state.group,
          groupOptions,
          isGroupData: true,
        },
        () => {
          this.getRegions(callback, isInit);
        }
      );
    } else {
      axiosConfig("/general/get-groups", {
        data: {
          network: this.state.network,
        },
      }).then((res) => {
        if (res.data.success === true) {
          groupOptions = groupOptions.concat(res.data.groups);
        }
        this.setState(
          {
            group: isInit
              ? !this.checkForce("isForceGroup") || this.checkPreload("group")
                ? this.context.group
                : 0
              : this.state.group,
            groupOptions,
            isGroupData: true,
          },
          () => {
            this.getRegions(callback, isInit);
          }
        );
      });
    }
  };

  getRegions = (callback, isInit = false) => {
    let regionOptions = [{ value: 0, text: "Toutes les régions" }];
    if (!this.context.group && !this.state.group) {
      this.setState(
        {
          region: isInit
            ? !this.checkForce("isForceRegion") || this.checkPreload("region")
              ? this.context.region
              : 0
            : this.state.region,
          regionOptions,
          isRegionData: true,
        },
        () => this.getAgencies(callback, isInit)
      );
    } else {
      axiosConfig({
        url: "/general/get-regions",
        data: {
          network: this.state.network,
          group: this.state.group,
        },
      }).then((res) => {
        if (res.data.success === true) {
          regionOptions = regionOptions.concat(res.data.regions);
        }
        this.setState(
          {
            region: isInit
              ? !this.checkForce("isForceRegion") || this.checkPreload("region")
                ? this.context.region
                : 0
              : this.state.region,
            regionOptions,
            isRegionData: true,
          },
          () => this.getAgencies(callback, isInit)
        );
      });
    }
  };

  getAgencies = (callback, isInit = false) => {
    let agencyOptions = [];

    if (this.props.notAllAgencies) {
      agencyOptions.push({
        value: 0,
        text: "Sélectionner une agence",
        disabled: true,
      });
    } else {
      agencyOptions.push({ value: 0, text: "Toutes les agences" });
    }

    if (!this.context.region && !this.state.region) {
      this.setState(
        {
          agency: isInit
            ? !this.checkForce("isForceAgency") || this.checkPreload("agency")
              ? this.context.agency
              : 0
            : this.state.agency,
          agencyOptions,
          isAgencyData: true,
        },
        callback
      );
    } else {
      axiosConfig({
        url: "/general/get-agencies",
        data: {
          network: this.state.network,
          group: this.state.group,
          region: this.state.region,
          isAll: this.props.isAll,
          isFake: this.props.isFake,
          userType: this.props.userType,
          manageAgency: this.context.manageAgency,
        },
      }).then(async (res) => {
        if (res.data.success === true) {
          agencyOptions = agencyOptions.concat(res.data.agencies);
        } else if (
          (this.props.isInterview || this.props.isSim) &&
          res.data.err === "no result"
        ) {
          await axiosConfig({
            url: "/general/get-agencies",
            data: {
              network: this.state.network,
              group: this.state.group,
              region: this.state.region,
              isAll: false,
              isFake: true,
              userType: this.props.userType.includes(5)
                ? [4, 5]
                : this.props.userType.includes(3)
                ? [3]
                : null,
              manageAgency: this.context.manageAgency,
            },
          }).then((result) => {
            if (result.data.success) {
              agencyOptions = agencyOptions.concat(result.data.agencies);
            }
          });
        }

        let existAgency = false;
        let selectAgency = false;
        if (this.context.agency) {
          existAgency =
            agencyOptions.filter((elem) => elem.value === this.context.agency)
              .length > 0;
          if (
            !existAgency &&
            this.props.isPerformance &&
            agencyOptions.length > 2
          ) {
            selectAgency = true;
          }
        }
        let agency = 0;
        if (isInit && existAgency) {
          if (
            !this.checkForce("isForceAgency") ||
            this.checkPreload("agency")
          ) {
            agency = this.context.agency;
          } else {
            agency = 0;
          }
        } else if (isInit && selectAgency) {
          agency = agencyOptions[1].value;
        } else if (isInit || !this.state.agency) {
          if (
            this.props.notAllAgencies &&
            agencyOptions.length &&
            (!this.state.agency ||
              (this.state.agency &&
                agencyOptions.filter((el) => el.value === this.state.agency)
                  .length === 0))
          ) {
            agency =
              agencyOptions[0].disabled &&
              agencyOptions.length > 1 &&
              this.state.region
                ? agencyOptions[1].value
                : agencyOptions[0].value;
          } else if (existAgency) {
            agency = this.state.agency;
          }
        } else {
          agency = this.state.agency;
        }
        if (this.props.isPerfEAD && isInit) {
          const fakeAgency = agencyOptions.filter((agency) => agency.fake);
          agency = fakeAgency.length ? fakeAgency[0].value : this.state.agency;
        }
        this.setState(
          {
            agency,
            agencyOptions,
            isAgencyData: true,
          },
          () => {
            if (
              (this.state.agency && this.props.isUser) ||
              this.state.notBlocked.includes("agency")
            ) {
              this.getUsers(callback, isInit);
            } else if (this.state.agency && this.props.isSim) {
              this.getSims(callback);
            } else {
              callback();
            }
          }
        );
      });
    }
  };

  getUsers = (callback, isInit = false) => {
    const limitUser = this.checkLimitUser();

    getUsers(
      this.state.network,
      this.state.group,
      this.state.region,
      this.props.isAgencyMultiples
        ? this.state.agencyMultiples
        : this.state.agency,
      this.props.userType ? this.props.userType : null,
      this.props.notAllUsers
        ? true
        : !this.context.role && this.props.notAllUsersType
        ? true
        : false,
      (userOptions) => {
        if (limitUser) {
          userOptions = userOptions.filter(
            (elem) => elem.value === this.context.id || elem.value === 0
          );
        }
        let existUser = false;
        if (this.context.id) {
          existUser =
            userOptions.filter((elem) => elem.value === this.context.id)
              .length > 0;
        }
        let user = 0;
        if (isInit && this.props.isDefaultAllUser) {
          user = this.state.user;
        } else if (isInit && existUser) {
          user = this.context.id;
        } else if (isInit || !this.state.user) {
          if (this.props.notAllUsers && userOptions.length) {
            user = userOptions[0].value;
          } else {
            user = this.state.user;
          }
        } else {
          user = this.state.user;
        }

        if (
          this.context.role &&
          this.props.allUserRole &&
          this.props.allUserRole[this.context.role]
        ) {
          user = 0;
        }

        this.setState(
          {
            userOptions,
            isUserData: true,
            user,
            userMultiples: [],
          },
          () => {
            if (this.state.user && this.props.isInterview) {
              this.getInterviews(callback);
            } else {
              callback();
            }
          }
        );
      },
      this.context.manageAgency
    );
  };

  getInterviews = (callback) => {
    let interviewOptions = [{ value: 0, text: "Aucun entretien" }];
    axiosConfig({
      url: "/general/get-interviews",
      data: {
        network: this.state.network,
        group: this.state.group,
        region: this.state.region,
        agency: this.state.agency,
        user: this.state.user,
        type: this.props.type,
        year: this.state.year,
      },
    }).then((res) => {
      if (res.data.success === true) {
        if (res.data.interviews.length) {
          interviewOptions = res.data.interviews;
        }
      }
      this.setState(
        {
          interviewOptions,
          isInterviewData: true,
          interview: interviewOptions[0].value,
        },
        callback
      );
    });
  };

  getSims = (callback) => {
    let simOptions = [{ value: 0, text: "Aucune SIM" }];
    axiosConfig({
      url: "/general/get-sims",
      data: {
        network: this.state.network,
        group: this.state.group,
        region: this.state.region,
        agency: this.state.agency ? this.state.agency : this.context.agency,
        user: this.state.user,
        type: this.props.type,
        year: this.state.year,
      },
    }).then((res) => {
      if (res.data.success === true) {
        if (res.data.sims.length) {
          simOptions = res.data.sims;
        }
      }
      this.setState(
        {
          simOptions,
          isSimData: true,
          sim: simOptions[0].value,
        },
        callback
      );
    });
  };

  // end - get data

  render() {
    let statusFakeAgency = 0;

    this.state.agencyMultiples.forEach((element) => {
      this.state.agencyOptions.forEach((agency) => {
        if (agency.value === element) {
          if (agency.fake) {
            statusFakeAgency = 2;
          } else {
            statusFakeAgency = 1;
          }
        }
      });
    });

    let countCMP = 0;
    let lg = 0;

    if (this.props.isYear) countCMP++;
    if (this.props.isDate) countCMP++;
    if (this.props.isDateSinisterStart) countCMP++;
    if (this.props.isDateSinisterEnd) countCMP++;
    if (this.props.isDateStart) countCMP++;
    if (this.props.isDateEnd) countCMP++;
    if (this.state.isNetwork) countCMP++;
    if (this.state.isGroup) countCMP++;
    if (this.state.isRegion) countCMP++;
    if (this.state.isAgency) countCMP++;
    if (this.props.isUser) countCMP++;
    if (this.props.isCompany) countCMP++;
    if (this.props.isCompanyMultiples) countCMP++;
    if (this.props.isInterview) countCMP++;
    if (this.props.isSim) countCMP++;
    if (
      this.props.isNatureEvent ||
      this.props.isNatureExpertise ||
      this.props.isCompanies ||
      this.props.isFiscalPower ||
      this.props.isKind ||
      this.props.isBrand
    )
      countCMP++;

    countCMP += this.props.countCMP ? this.props.countCMP : 0;

    if (countCMP > 6) {
      lg = Math.round(24 / countCMP);
    } else {
      lg = Math.round(12 / countCMP);
    }

    const yearCMP = (
      <Col xs={12} md={4} lg={lg} className={"mb-10"}>
        <FormSelect
          title="Année"
          name="year"
          value={this.state.year ? this.state.year : 0}
          ignore={true}
          options={this.state.yearOptions}
          onChange={this.handleInputChange}
        />
      </Col>
    );

    const dateCMP = (
      <Col xs={12} md={4} lg={lg} className={"mb-10"}>
        <span
          className="mt-auto"
          style={{
            display: "inline-block",
            fontSize: "14px",
            fontWeight: "bold",
            color: "var(--black)",
            marginBottom: "8px",
          }}
        >
          {this.props.dateName ? this.props.dateName : "Date de début"}
        </span>
        <Button
          outline
          text={moment(this.state.date).format("DD/MM/YYYY")}
          onClick={() => {
            this.setState({
              isCalendar: true,
              nameCalendar: "date",
              minDateCalendar: null,
              maxDateCalendar: null,
            });
          }}
        />
      </Col>
    );

    const dateSinisterStartCMP = (
      <Col xs={12} md={4} lg={lg} className={"mb-10"}>
        <span
          className="mt-auto"
          style={{
            display: "inline-block",
            fontSize: "14px",
            fontWeight: "bold",
            color: "var(--black)",
            marginBottom: "8px",
          }}
        >
          Date de début du sinistre
        </span>
        <Button
          outline
          text={moment(this.state.dateSinisterStart).format("DD/MM/YYYY")}
          onClick={() => {
            this.setState({
              isCalendar: true,
              nameCalendar: "dateSinisterStart",
              minDateCalendar: null,
              maxDateCalendar: this.props.isDateNoLimit
                ? ""
                : moment().format("YYYY-MM-DD"),
            });
          }}
        />
      </Col>
    );

    const dateSinisterEndCMP = (
      <Col xs={12} md={4} lg={lg} className={"mb-10"}>
        <span
          className="mt-auto"
          style={{
            display: "inline-block",
            fontSize: "14px",
            fontWeight: "bold",
            color: "var(--black)",
            marginBottom: "8px",
          }}
        >
          Date de fin du sinistre
        </span>
        <Button
          outline
          text={moment(this.state.dateSinisterEnd).format("DD/MM/YYYY")}
          onClick={() => {
            this.setState({
              isCalendar: true,
              nameCalendar: "dateSinisterEnd",
              minDateCalendar: null,
              maxDateCalendar: this.props.isDateNoLimit
                ? ""
                : moment().format("YYYY-MM-DD"),
            });
          }}
        />
      </Col>
    );

    const dateStartCMP = (
      <Col xs={12} md={4} lg={lg} className={"mb-10"}>
        <span
          className="mt-auto"
          style={{
            display: "inline-block",
            fontSize: "14px",
            fontWeight: "bold",
            color: "var(--black)",
            marginBottom: "8px",
          }}
        >
          {this.props.dateStartName
            ? this.props.dateStartName
            : "Date de début"}
        </span>
        <Button
          outline
          text={moment(this.state.dateStart).format("DD/MM/YYYY")}
          onClick={() => {
            this.setState({
              isCalendar: true,
              nameCalendar: "dateStart",
              minDateCalendar: null,
              maxDateCalendar: moment().format("YYYY-MM-DD"),
            });
          }}
        />
      </Col>
    );

    const dateEndCMP = (
      <Col xs={12} md={4} lg={lg} className={"mb-10"}>
        <span
          className="mt-auto"
          style={{
            display: "inline-block",
            fontSize: "14px",
            fontWeight: "bold",
            color: "var(--black)",
            marginBottom: "8px",
          }}
        >
          {this.props.dateEndName ? this.props.dateEndName : "Date de fin"}
        </span>
        <Button
          outline
          text={moment(this.state.dateEnd).format("DD/MM/YYYY")}
          onClick={() => {
            this.setState({
              isCalendar: true,
              nameCalendar: "dateEnd",
              minDateCalendar: this.state.dateStart,
              maxDateCalendar: moment().format("YYYY-MM-DD"),
            });
          }}
        />
      </Col>
    );

    const networkCMP = (
      <Col xs={12} md={4} lg={lg}>
        <FormSelect
          title="Réseau"
          name="network"
          value={this.state.network ? this.state.network : 0}
          ignore={true}
          options={this.state.networkOptions}
          onChange={this.handleInputChange}
        />
      </Col>
    );

    const groupCMP = (
      <Col xs={12} md={4} lg={lg}>
        <FormSelect
          disabled={!this.state.network}
          title="Groupe"
          name="group"
          value={this.state.group ? this.state.group : 0}
          ignore={true}
          options={this.state.groupOptions}
          onChange={this.handleInputChange}
        />
      </Col>
    );

    const regionCMP = (
      <Col xs={12} md={4} lg={lg}>
        <FormSelect
          disabled={!this.state.group}
          title="Région"
          name="region"
          value={this.state.region ? this.state.region : 0}
          ignore={true}
          options={this.state.regionOptions}
          onChange={this.handleInputChange}
        />
      </Col>
    );

    const agencyCMP = (
      <Col xs={12} md={4} lg={lg}>
        <FormSelect
          disabled={!this.state.region}
          title="Agence"
          name="agency"
          value={this.state.agency ? this.state.agency : 0}
          ignore={true}
          options={this.state.agencyOptions}
          onChange={this.handleInputChange}
        />
      </Col>
    );

    const agencyMultiplesCMP = (
      <Col xs={12} md={4} lg={lg} className={"mb-10"}>
        <FormSelectMultiples
          disabled={!this.state.region}
          title="Agence(s)"
          value={this.state.agencyMultiples}
          default="Toutes les agences"
          options={this.state.agencyOptions.filter(
            (element) =>
              element.value !== 0 &&
              (!statusFakeAgency ||
                (statusFakeAgency === 1 && !element.fake) ||
                (statusFakeAgency === 2 && element.fake))
          )}
          onChange={(value) =>
            this.setState(
              {
                agencyMultiples: [...value],
              },
              () => {
                this.getUsers(this.checkInput);
                this.handleDataChange();
              }
            )
          }
        />
      </Col>
    );

    const userCMP = (
      <Col xs={12} md={4} lg={lg} className={"mb-10"}>
        <FormSelect
          disabled={
            !this.state.notBlocked.includes("agency") &&
            !this.state.agency &&
            (!this.state.agencyMultiples || !this.state.agencyMultiples.length)
          }
          title="Utilisateur-trice"
          name="user"
          value={this.state.user ? this.state.user : 0}
          ignore={true}
          options={this.state.userOptions}
          onChange={this.handleInputChange}
        />
      </Col>
    );

    const userMultiplesCMP = (
      <Col xs={12} md={4} lg={lg} className={"mb-10"}>
        <FormSelectMultiples
          disabled={!this.state.agency}
          title="Utilisateur(s)"
          value={this.state.userMultiples}
          default="Toutes les utilisateurs"
          options={this.state.userOptions.filter(
            (element) => element.value !== 0
          )}
          onChange={(value) =>
            this.setState(
              {
                userMultiples: [...value],
              },
              () => {
                this.handleDataChange();
              }
            )
          }
        />
      </Col>
    );

    const companyCMP = (
      <Col xs={12} md={4} lg={lg} className={"mb-10"}>
        <FormSelect
          title="Compagnie"
          name="company"
          value={this.state.company ? this.state.company : 0}
          ignore={true}
          options={this.context.companyOptions}
          onChange={this.handleInputChange}
        />
      </Col>
    );

    const companyMultiplesCMP = (
      <Col xs={12} md={4} lg={lg} className={"mb-10"}>
        <FormSelectMultiples
          title="Compagnies"
          value={this.state.companyMultiples}
          default="Toutes les compagnies"
          options={this.context.companyOptions.filter(
            (element) => element.value !== 0
          )}
          onChange={(value) =>
            this.setState(
              {
                companyMultiples: value,
              },
              () => this.handleDataChange()
            )
          }
        />
      </Col>
    );

    const interviewCMP = (
      <Col xs={12} md={4} lg={lg} className={"mb-10"}>
        <FormSelect
          disabled={!this.state.user}
          title="Entretien"
          name="interview"
          value={this.state.interview ? this.state.interview : 0}
          ignore={true}
          options={this.state.interviewOptions}
          onChange={this.handleInputChange}
        />
      </Col>
    );

    const simCMP = (
      <Col xs={12} md={4} lg={lg} className={"mb-10"}>
        <FormSelect
          disabled={!this.state.agency}
          title="SIM"
          name="sim"
          value={this.state.sim ? this.state.sim : 0}
          ignore={true}
          options={this.state.simOptions}
          onChange={this.handleInputChange}
        />
      </Col>
    );

    const detailsCMP = (
      <Col xs={12} md={4} lg={lg} className={"mt-auto mb-10"}>
        <Button
          onClick={() => this.setState({ isDetails: !this.state.isDetails })}
          text=" Filtres avancés"
        />
      </Col>
    );

    const natureEventCMP = (
      <Col xs={12} md={12} className={"mb-10"}>
        <p>
          <b>Nature d'évènement</b>
        </p>
        {this.context.natureEventOptions.map((element, idx) => (
          <FormCheckbox
            key={idx}
            className="m-auto mr-5"
            name={`natureEvent-${element.value}`}
            checked={this.state.natureEvent[element.value]}
            onChange={this.handleCheckboxChange}
            text={element.text}
          />
        ))}
      </Col>
    );

    const natureExpertiseCMP = (
      <Col xs={12} md={12} className={"mb-10"}>
        <p>
          <b>Nature d'expertise</b>
        </p>
        {this.context.natureExpertiseOptions.map((element, idx) =>
          !this.props.excludeNatureExpertise ||
          !this.props.excludeNatureExpertise.includes(element.value) ? (
            <FormCheckbox
              key={idx}
              className="m-auto mr-5"
              name={`natureExpertise-${element.value}`}
              checked={this.state.natureExpertise[element.value]}
              onChange={this.handleCheckboxChange}
              text={element.text}
            />
          ) : null
        )}
      </Col>
    );

    const companiesCMP = (
      <Col xs={12} md={12} className={"mb-10"}>
        <p>
          <b>Compagnies</b>
        </p>
        {this.context.companyOptions.map((element, idx) =>
          idx !== 0 &&
          (!this.props.excludeCompanies ||
            !this.props.excludeCompanies.includes(element.value)) ? (
            <FormCheckbox
              key={idx}
              className="m-auto mr-5"
              name={`companies-${element.value}`}
              checked={this.state.companies[element.value]}
              onChange={this.handleCheckboxChange}
              text={element.text}
            />
          ) : null
        )}
      </Col>
    );

    const fiscalPowerCMP = (
      <Col xs={12} md={12} className={"mb-10"}>
        <p>
          <b>Puissance fiscale</b>
        </p>
        {this.context.fiscalPowerOptions.map((element, idx) => (
          <FormCheckbox
            key={idx}
            className="m-auto mr-5"
            name={`fiscalPower-${element}`}
            checked={this.state.fiscalPower[element]}
            onChange={this.handleCheckboxChange}
            text={element}
          />
        ))}
      </Col>
    );

    const kindCMP = (
      <Col xs={12} md={12} className={"mb-10"}>
        <FormSelectMultiples
          disabled={!this.state.region}
          title="Genre(s) véhicule"
          value={this.state.kind}
          default="Tous les genres"
          options={this.state.kindOptions.map((element) => ({
            value: element,
            text: element,
          }))}
          onChange={(value) =>
            this.setState(
              {
                kind: [...value],
              },
              () => {
                this.handleDataChange();
              }
            )
          }
        />
      </Col>
    );

    const brandCMP = (
      <Col xs={12} md={12} className={"mb-10"}>
        <p>
          <b>Marque</b>
        </p>
        {this.context.brandOptions.map((element, idx) => (
          <FormCheckbox
            key={idx}
            className="m-auto mr-5"
            name={`brand-${element}`}
            checked={this.state.brand[element]}
            onChange={this.handleCheckboxChange}
            text={element}
          />
        ))}
      </Col>
    );

    const garageCMP = (
      <Col xs={12} md={6} lg={4} className={"mb-10"}>
        <FormInput
          type="text"
          title={"Réparateur / Code (;)"}
          name="garage"
          value={this.state.garage}
          onChange={this.handleInputChangeText}
          onBlur={() => this.handleDataChange()}
        />
      </Col>
    );

    const clientZipCodeCMP = (
      <Col xs={12} md={6} lg={4} className={"mb-10"}>
        <FormInput
          type="text"
          title={"Code postal assuré (;)"}
          name="clientZipCode"
          value={this.state.clientZipCode}
          onChange={this.handleInputChangeText}
          onBlur={() => this.handleDataChange()}
        />
      </Col>
    );

    const garageApprovedCMP = (
      <Col xs={12} md={12} className={"mb-10"}>
        <p>
          <b>Garage agréé / non agréé</b>
        </p>
        <div className="d-flex">
          <FormRadio
            className="mr-10"
            name="garageApproved"
            value={"all"}
            checked={this.state.garageApproved === "all"}
            onChange={this.handleRadioChange}
            text="Tous"
          />
          <FormRadio
            className="mr-10"
            name="garageApproved"
            value={"approved"}
            checked={this.state.garageApproved === "approved"}
            onChange={this.handleRadioChange}
            text="Agréé"
          />
          <FormRadio
            className="mr-10"
            name="garageApproved"
            value={"unapproved"}
            checked={this.state.garageApproved === "unapproved"}
            onChange={this.handleRadioChange}
            text="Non agréé"
          />
        </div>
      </Col>
    );

    const builderCMP = (
      <Col xs={12} md={4} lg={lg} className={"mb-10"}>
        <FormSelect
          title="Origine"
          name="builder"
          value={this.state.builder ? this.state.builder : 0}
          ignore={true}
          options={this.state.builderOptions}
          onChange={this.handleInputChange}
        />
      </Col>
    );
    const projetEBrandCMP = (
      <Col xs={12} md={4} lg={lg} className={"mb-10"}>
        <FormSelectMultiples
          title="Marque(s) véhicule"
          value={this.state.projetEBrand}
          default="Toutes les marques"
          options={this.context.brandOptions.map((el) => ({
            value: el,
            text: el,
          }))}
          onChange={(value) => {
            this.setState(
              {
                projetEBrand: [...value],
              },
              () => {
                this.handleDataChange();
              }
            );
          }}
        />
      </Col>
    );

    return (
      <>
        <Card className={this.props.className}>
          <Row>
            {this.props.isYear ? yearCMP : null}
            {this.props.isDate ? dateCMP : null}
            {this.props.isDateSinisterStart ? dateSinisterStartCMP : null}
            {this.props.isDateSinisterEnd ? dateSinisterEndCMP : null}
            {this.props.isDateStart ? dateStartCMP : null}
            {this.props.isDateEnd ? dateEndCMP : null}
            {this.state.isNetwork ? networkCMP : null}
            {this.state.isGroup ? groupCMP : null}
            {this.state.isRegion ? regionCMP : null}
            {this.state.isAgency && !this.props.isAgencyMultiples
              ? agencyCMP
              : null}
            {this.state.isAgency && this.props.isAgencyMultiples
              ? agencyMultiplesCMP
              : null}
            {this.props.isUser && !this.props.isUserMultiples ? userCMP : null}
            {this.props.isUser && this.props.isUserMultiples
              ? userMultiplesCMP
              : null}
            {this.props.isCompany ? companyCMP : null}
            {this.props.isCompanyMultiples ? companyMultiplesCMP : null}
            {this.props.isInterview ? interviewCMP : null}
            {this.props.isSim ? simCMP : null}
            {this.props.isGarage ? garageCMP : null}
            {this.props.isClientZipCode ? clientZipCodeCMP : null}
            {this.props.isNatureEvent ||
            this.props.isNatureExpertise ||
            this.props.isCompanies ||
            this.props.isFiscalPower ||
            this.props.isKind ||
            this.props.isGarageApproved
              ? detailsCMP
              : null}
            {this.state.isDetails && this.props.isNatureEvent
              ? natureEventCMP
              : null}
            {this.state.isDetails && this.props.isNatureExpertise
              ? natureExpertiseCMP
              : null}
            {this.state.isDetails && this.props.isCompanies
              ? companiesCMP
              : null}
            {this.state.isDetails && this.props.isFiscalPower
              ? fiscalPowerCMP
              : null}
            {this.state.isDetails && this.props.isKind ? kindCMP : null}
            {this.state.isDetails && this.props.isBrand ? brandCMP : null}
            {this.state.isDetails && this.props.isGarageApproved
              ? garageApprovedCMP
              : null}
            {this.props.isProjetE ? builderCMP : null}
            {this.props.isProjetE ? projetEBrandCMP : null}

            {this.props.isChange ? (
              <Col xs={12} md={4} lg={lg} className={"mt-10 mb-10 d-flex"}>
                <Button
                  text="Rechercher"
                  className={this.state.isChange ? "bg-red mt-auto" : "mt-auto"}
                  style={
                    this.state.isChange ? { borderColor: "var(--red)" } : {}
                  }
                  onClick={() => this.handleDataChange(true)}
                >
                  <FontAwesomeIcon icon={faSync} />
                </Button>
              </Col>
            ) : null}
          </Row>
          {this.props.others}
        </Card>
        <Calendar
          show={this.state.isCalendar}
          valueDate={this.state[this.state.nameCalendar]}
          minDate={this.state.minDateCalendar}
          maxDate={this.props.isDateNoLimit ? "" : this.state.maxDateCalendar}
          isWeek
          onClose={() =>
            this.setState({
              isCalendar: false,
              dateCalendar: null,
              nameCalendar: null,
              minDateCalendar: null,
              maxDateCalendar: null,
            })
          }
          onValid={(valueDate) => {
            let dateStart = this.state.dateStart;
            let dateEnd = this.state.dateEnd;
            let dateSinisterEnd = this.state.dateSinisterEnd;

            if (
              this.state.nameCalendar === "dateStart" &&
              moment(this.state.dateEnd).format("YYYY") !==
                moment(valueDate).format("YYYY") &&
              !this.props.disableForceDate
            ) {
              dateStart = valueDate;
              if (
                moment(dateStart).format("YYYY") === moment().format("YYYY")
              ) {
                dateEnd = moment(valueDate).format("YYYY-MM-DD");
              } else {
                dateEnd = moment(valueDate).format("YYYY-12-31");
              }
            } else if (this.state.nameCalendar === "dateStart") {
              dateStart = valueDate;
            } else if (
              this.state.nameCalendar === "dateEnd" &&
              moment(this.state.dateStart).format("YYYY") !==
                moment(valueDate).format("YYYY")
            ) {
              dateStart = moment(valueDate).format("YYYY-01-01");
              dateEnd = valueDate;
            } else if (this.state.nameCalendar === "dateEnd") {
              dateEnd = valueDate;
            } else if (this.state.nameCalendar === "dateSinisterStart") {
              dateStart = valueDate;
              dateSinisterEnd = valueDate;
            } else if (this.state.nameCalendar === "dateSinisterEnd") {
              dateSinisterEnd = valueDate;
            }

            this.setState(
              {
                isCalendar: false,
                [this.state.nameCalendar]: valueDate,
                dateSinisterEnd,
                dateStart,
                dateEnd,
                minDateCalendar: null,
                maxDateCalendar: null,
              },
              () => this.handleDataChange()
            );
          }}
        />
      </>
    );
  }
}
